import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/assets/zitadel.config.json')) {
      return next.handle(req);
    }

    // Obtaining the configuration of the OIDC client
    return this.oidcSecurityService.getConfiguration().pipe(
      switchMap(config => {
        // User is probably not yet authenticated, skipping request for now
        // if (!config.secureRoutes) {
        //   return next.handle(req);
        // }

        // Checking if the URL has to be secured
        if (config.secureRoutes.find(x => req.url.startsWith(x))) {
          // URL has to be secured because she is in the secureRoutes list
          return this.oidcSecurityService.getAccessToken().pipe(
            switchMap(accessToken => {

              req = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              return next.handle(req);
            }),
          );
        }

        // URL hasn't to be secured
        return next.handle(req);
      }),
    );
  }
}
