import {Component, OnInit} from '@angular/core';
import {DataService} from './services/data.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {NzCardComponent} from 'ng-zorro-antd/card';
import {NzTypographyComponent} from 'ng-zorro-antd/typography';
import {NzRowDirective, NzColDirective} from 'ng-zorro-antd/grid';
import {NzMenuDirective, NzMenuItemComponent} from 'ng-zorro-antd/menu';
import {NzDropDownADirective, NzDropDownDirective, NzDropdownMenuComponent} from 'ng-zorro-antd/dropdown';
import {NzIconDirective} from 'ng-zorro-antd/icon';
import {NzPopoverDirective} from 'ng-zorro-antd/popover';
import {NgOptimizedImage} from '@angular/common';
import {NzTooltipDirective} from 'ng-zorro-antd/tooltip';
import {RouterLink, RouterOutlet} from '@angular/router';
import {NzLayoutComponent, NzHeaderComponent, NzContentComponent} from 'ng-zorro-antd/layout';
import {ThemeService, ThemeType} from './services/theme.service';
import {NzFlexDirective} from 'ng-zorro-antd/flex';
import {NzButtonComponent} from 'ng-zorro-antd/button';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NzLayoutComponent, NzHeaderComponent, RouterLink, NzTooltipDirective, NgOptimizedImage, NzPopoverDirective, NzIconDirective, NzDropDownADirective, NzDropDownDirective, NzDropdownMenuComponent, NzMenuDirective, NzMenuItemComponent, NzContentComponent, RouterOutlet, NzRowDirective, NzColDirective, NzTypographyComponent, NzCardComponent, NzFlexDirective, NzButtonComponent],
})
export class AppComponent implements OnInit {
  pageTitle: string;
  fullName: string;
  menuVisible = false;

  constructor(private dataService: DataService,
              private oidcSecurityService: OidcSecurityService,
              protected themeService: ThemeService) {
    this.dataService.pageTitle.subscribe(pageTitle => this.pageTitle = pageTitle);
    themeService.init();
  }

  async ngOnInit() {
    this.dataService.username.subscribe(value => {
      this.fullName = value;
    });

    this.oidcSecurityService.isAuthenticated().subscribe(async authenticated => {
      if (authenticated) {
        await this.dataService.boot();
      }
    });
  }

  myAccount() {
    window.open('https://login.amphibole-solutions.tech/ui/console/me', '_blank');
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe();
  }

  closeMenu() {
    this.menuVisible = false;
  }

  toggleTheme() {
    this.themeService.toggleTheme().then();
  }

  protected readonly ThemeType = ThemeType;
}
